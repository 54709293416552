var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{class:_vm.$style.container},[_c('ArrowUp',{attrs:{"link":"#"}}),_c('header',{class:[_vm.$style.header, _vm.$style[("" + _vm.slug)]]},[_c('div',{class:_vm.$style.inner},[_c('h2',{class:_vm.$style.title},[_vm._v(_vm._s(_vm.fichaData.title))]),_c('p',{class:_vm.$style.year},[_vm._v(_vm._s(_vm.fichaData.year))])])]),_c('main',[_c('section',{class:_vm.$style.video},[_c('div',{class:_vm.$style.inner},[_c('div',{class:_vm.$style.player},[_c('iframe',{class:_vm.$style.iframe,attrs:{"src":("https://www.youtube.com/embed/" + (_vm.sound
                ? _vm.fichaData.videos[_vm.selected].id
                : ((_vm.fichaData.videos[_vm.selected].id) + "?mute=1"))),"title":_vm.fichaData.videos[_vm.selected].title,"allow":"accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture","allowfullscreen":""}}),(_vm.fichaData.videos.length > 1)?_c('div',{class:_vm.$style.controls},[_c('CarouselControls',{attrs:{"current":_vm.selected + 1,"length":_vm.fichaData.videos.length,"show-numbers":""},on:{"move-prev":function($event){return _vm.move('prev')},"move-next":function($event){return _vm.move('next')}}})],1):_vm._e()]),(_vm.fichaData.playlist)?_c('a',{class:_vm.$style.playlist,attrs:{"href":_vm.fichaData.playlist,"target":"_blank"}},[_vm._v(_vm._s(_vm.$t("cine.watch_all_videos")))]):_vm._e()])]),_c('section',{class:_vm.$style.info},[_c('div',{class:_vm.$style.about},[_c('div',[_c('h3',{class:_vm.$style.title},[_vm._v(_vm._s(_vm.$t("cine.about_project")))]),_c('p',{class:[_vm.$style.text, _vm.$style.short]},[_c('strong',{domProps:{"innerHTML":_vm._s(_vm.fichaData.about.short)}})]),_c('p',{class:_vm.$style.text,domProps:{"innerHTML":_vm._s(_vm.fichaData.about.description)}}),(_vm.fichaData.fullMovie)?_c('a',{class:_vm.$style.fullMovie,attrs:{"href":_vm.fichaData.fullMovie,"target":"_blank"}},[_vm._v(" "+_vm._s(_vm.$t("cine.full_movie"))+" ")]):_vm._e()]),(_vm.fichaData.poster)?_c('div',{class:_vm.$style.poster},[_c('figure',[_c('img',{class:_vm.$style.image,attrs:{"src":_vm.fichaData.poster,"alt":("Póster de " + (_vm.fichaData.title))}})])]):_vm._e()]),_c('div',{class:_vm.$style.ficha},[_c('h4',{class:_vm.$style.title},[_vm._v(_vm._s(_vm.$t("cine.staff")))]),_c('ul',{class:_vm.$style.list},_vm._l((_vm.fichaData.participantes),function(ref,idx){
            var item = ref.item;
return _c('li',{key:("participants-" + idx),class:_vm.$style.item,domProps:{"innerHTML":_vm._s(item)}})}),0)])])]),_c('aside',{class:_vm.$style.navigateToProject},[_c('ul',{class:_vm.$style.list},[_c('li',[[_c('router-link',{attrs:{"to":_vm.getPreviousOne(_vm.fichaData.id),"custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var href = ref.href;
            var navigate = ref.navigate;
return [_c('a',{class:[_vm.$style.item, _vm.$style.prev],attrs:{"href":href},on:{"click":navigate}},[_vm._v(" "+_vm._s(_vm.$t("cine.navigation.prev"))+" ")])]}}])})]],2),_c('li',[[_c('router-link',{attrs:{"to":_vm.getNextOne(_vm.fichaData.id),"custom":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var href = ref.href;
            var navigate = ref.navigate;
return [_c('a',{class:[_vm.$style.item, _vm.$style.next],attrs:{"href":href},on:{"click":navigate}},[_vm._v(" "+_vm._s(_vm.$t("cine.navigation.next"))+" ")])]}}])})]],2)])])],1)}
var staticRenderFns = []

export { render, staticRenderFns }